<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

import TimeAgo from 'vue2-timeago';
import {OrbitSpinner} from "epic-spinners";

import {get_acsrf_token} from "@/methods";

export default {
  components: {
    VclCode,
    TimeAgo,
    OrbitSpinner
  },
  props: {

  },
  computed: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/balances', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.data = data;

          let colors = [];
          let labels = [];
          this.balances.series = [];

          this.data.accounts.forEach((account) => {
            if(!account.color) account.color = this.derivedColor(account.color_seed);

            this.balances.series.push(account.percentage);
            colors.push(account.color);
            labels.push(account.title);
          });

          if(this.ready) {
            this.$refs.balanceChart.updateSeries(this.balances.series);
            this.$refs.balanceChart.updateOptions({colors: colors, labels: labels});
          } else {
            this.balances.chartOptions.colors = colors;
            this.balances.chartOptions.labels = labels;
          }

          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    randomColor() {
      return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
    },
    derivedColor(value) {
      return '#'+(value*0xFFFFFF<<0).toString(16);
    },
    async syncBalances() {
      this.inProgress = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/accounting/sevDesk/balances/sync`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if(response.ok) {
          this.getData();
          let data = await response.json();
          if(data.status) {
            if(data.count === 0) {
              this.$toast.info(`No new transactions`);
            } else {
              this.$toast.success(`Imported ${data.count} new transactions`);
            }
          } else {
            this.$toast.warning(`sevDesk import failed`);
          }
        } else {
          if (response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.title'));

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.$toast.error(this.$t('error.server.generic.title'));
      }

      this.inProgress = false;
    }
  },
  created() {
    this.getData();
    this.timer = setInterval(() => {this.getData();}, 1000 * 60 * 5);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      data: {},
      timer: null,
      inProgress: false,
      balances: {
        series: [],
        chartOptions: {
          chart: {
            height: 300,
            type: 'radialBar',
          },
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: 0,
              endAngle: 270,
              hollow: {
                margin: 5,
                size: '35%',
                background: 'transparent',
                image: undefined,
              },
              track: {
                show: true,
                startAngle: undefined,
                endAngle: undefined,
                background: '#f2f2f2',
                strokeWidth: '97%',
                opacity: 1,
                margin: 12,
                dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  blur: 3,
                  opacity: 0.5
                }
              },
              dataLabels: {
                name: {
                  show: true,
                  fontSize: '16px',
                  fontWeight: 600,
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  offsetY: 4,
                  formatter: function (val) {
                    return val + '%'
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  fontSize: '16px',
                  fontFamily: undefined,
                  fontWeight: 600,
                  formatter: function (w) {
                    return Math.min(w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0), 100)+ '%';
                  }
                }
              }
            }
          },
          stroke: {
            lineCap: 'round'
          },
          colors: [],
          labels: [],
          legend: {
            show: false,
          },
        }
      }
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-3">Company Balance</h4>

        <div class="row">
          <div class="col-lg-3">
            <div class="mt-4">
              <p>Available Total Balance</p>
              <h4>{{ $n(data.total_balance, {locale: 'de'}) }} €</h4>

              <!--
              <div class="row">
                <div class="col-6">
                  <div>
                    <p class="mb-2">Income</p>
                    <h5>$ 2632.46</h5>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="mb-2">Expense</p>
                    <h5>$ 924.38</h5>
                  </div>
                </div>
              </div>
              -->

              <div class="mt-4">
                <button class="btn btn-primary btn-lg btn-block" :class="{'disabled': inProgress}" :disabled="inProgress" v-on:click="syncBalances()">
                  <template v-if="inProgress">
                    <orbit-spinner
                        :animation-duration="1200"
                        :size="16"
                        class="align-middle d-inline-block"
                    />
                  </template>
                  <template v-else>
                    Sync Balances
                    <i class="far fa-retweet-alt"></i>
                  </template>
                </button>
              </div>

            </div>
          </div>

          <div class="col-lg-5 col-sm-6">
            <div>
              <apexchart
                  ref="balanceChart"
                  class="apex-charts"
                  dir="ltr"
                  type="radialBar"
                  height="300"
                  :series="balances.series"
                  :options="balances.chartOptions"
              ></apexchart>
            </div>
          </div>

          <div class="col-lg-4 col-sm-6 align-self-center">
            <div v-for="account in data.accounts" :key="account.id">
              <p class="mb-2">
                <i class="mdi mdi-circle align-middle font-size-10 me-2" :style="'color: '+account.color+';'"></i>
                {{ account.title }}
                <template v-if="account.type === 'PAYPAL'">
                  <i class="fab fa-paypal"/>
                </template>
                <template v-else-if="account.type === 'FINAPI'">
                  <i class="fas fa-university"/>
                </template>
                <template v-else-if="account.identifier">
                  <template v-if="account.identifier === 'STRIPE'">
                    <i class="fab fa-stripe-s"/>
                  </template>
                </template>
              </p>
              <h5>
                {{ $n(account.balance, {locale: 'de'}) }} €
                <span class="text-muted small" v-if="account.last_sync">
                  <i class="far fa-retweet-alt" />
                  {{ account.type }}
                  <time-ago  :refresh="60" :datetime="parseDate(account.last_sync)" long :locale="'de'"></time-ago>
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
